import React from 'react';
import { Row, Col, Checkbox } from 'antd';

export const CheckboxSelection = ({
  col1,
  col2,
  title,
  options,
  defaultValue,
  onChange,
  onClick,
  selected,
  show,
  msg,
  msgShow,
  isEn,
}: {
  col1: number;
  col2: number;
  title: string;
  options: any[];
  defaultValue: any[];
  onChange: any;
  onClick: any;
  selected: string[];
  show: boolean;
  msg: string;
  msgShow: boolean;
  isEn? :boolean;
}) => {
  let regions = [
    '서울',
    '부산',
    '대구',
    '인천',
    '광주',
    '대전',
    '울산',
    '경기',
    '강원',
    '충북',
    '충남',
    '전북',
    '전남',
    '경북',
    '경남',
    '제주',
    '세종',
  ];

  let regionsEn = [
    "Seoul",
    "Busan",
    "Daegu",
    "Incheon",
    "Gwangju",
    "Daejeon",
    "Ulsan",
    "Gyeonggi",
    "Gangwon",
    "Chungbuk",
    "Chungnam",
    "Jeonbuk",
    "Jeonnam",
    "Gyeongbuk",
    "Gyeongnam",
    "Jeju",
    "Sejong"
  ];

  return (
    <div className="selection-container">
      {show ? (
        <Row>
          <Col span={col1}>
            <span className="selection-title">{title}</span>
          </Col>
          <Col span={col2}>
            <Checkbox.Group
              onChange={onChange}
              defaultValue={defaultValue}
              style={{ width: '540px' }}
              value={selected}
            >
              <Row>
                {options.map((item, index) => (
                  <Col span={12} style={{ marginBottom: '20px' }}>
                    <Checkbox
                      value={item}
                      key={`title-${index}`}
                      className="selection-text"
                    >
                      <span style={{ marginLeft: '8px' }}>{item}</span>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
            {selected.includes('지역 더 보기') ? (
              <div className="region-container">
                {regions.map((item, index) => (
                  <input
                    type="button"
                    value={item}
                    key={`region-${index}`}
                    onClick={onClick}
                    className="region-button button-ko"
                  />
                ))}
              </div>
            ) : null}
            {selected.includes('More areas') ? (
              <div className="region-container">
                {regionsEn.map((item, index) => (
                  <input
                    type="button"
                    value={item}
                    key={`region-${index}`}
                    onClick={onClick}
                    className="region-button button-en"
                  />
                ))}
              </div>
            ) : null}
            {msgShow ? (
              <p className="desc-msg">
                {msg}은{' '}
                <a
                  href="https://link.pocketsurvey.co.kr/p/I_Qkh78"
                  target="_blank"
                  className="small-contact-us"
                  rel="noreferrer"
                >
                  문의하기
                </a>
                를 통해 요청해주세요.
              </p>
            ) : null}
            {isEn ? (
              <p className="desc-msg">
              *including Seoul,  Incheon metropolis and Gyeonggi province
              </p>
            ): null}
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
