import React from 'react';
import { Row, Col, Radio } from 'antd';
export const RadioSelection = ({
  title,
  name,
  options,
  onChange,
  value,
  defaultValue,
}: {
  title: string;
  name: string;
  options: any;
  onChange: any;
  value: any;
  defaultValue: any;
}) => {
  return (
    <Row align="middle" justify="center" style={{ marginBottom: '30px' }}>
      <Col span={5}>
        <p className="selection-title">{title}</p>
      </Col>
      <Col span={19}>
        {/* <Radio.Group
          name={name}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          className="selection-text"
        > */}
        <Row>
          {options.map((item: any, index: number) => (
            <Col span={12} key={index}>
              <input
                type="radio"
                className="radioInput"
                value={item.value}
                key={item.value}
                name={title}
                onChange={onChange}
                checked={item.value === value ? true : false}
              />
              <label className="radio-text">{item.label}</label>
            </Col>
          ))}
        </Row>
        {/* </Radio.Group> */}
      </Col>
    </Row>
  );
};
