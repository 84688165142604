import React, { useEffect, useState } from 'react';
import { Row, Col, Slider } from 'antd';
import './styles.scss';
import 'antd/dist/antd.css';

const SendingEstimate = () => {
  const [cost, setCost] = useState<any>(0);
  const [shortCost, setShortCost] = useState<any>(0);
  const [slider1, setSlider1] = useState<any>('');

  const getPrice = (count: number) => {
    if (count < 2000) {
      return 120;
    } else if (count >= 2000 && count < 5000) {
      return 100;
    } else if (count >= 5000 && count < 20000) {
      return 80;
    } else if (count >= 20000 && count < 50000) {
      return 70;
    } else if (count >= 50000 && count < 100000) {
      return 60;
    } else if (count >= 100000 && count < 150000) {
      return 50;
    } else if (count >= 150000) {
      return 40;
    }
  };

  function comma(num: string) {
    var len, point, str;

    num = num + '';
    point = num.length % 3;
    len = num.length;

    str = num.substring(0, point);
    while (point < len) {
      if (str !== '') str += ',';
      str += num.substring(point, point + 3);
      point += 3;
    }
    return str;
  }

  function isNumeric(string: string) {
    return !isNaN(Number(string));
  }

  function onChangeInput(e: any) {
    let numVal = '';
    for (let i = 0; i < e.target.value.length; i++) {
      if (e.target.value[i] !== ',') {
        numVal += e.target.value[i];
      }
    }
    if (isNumeric(numVal) && numVal.length < 9) {
      setSlider1(numVal);
    }
  }

  function setDefault() {
    setSlider1(1500);
  }

  useEffect(() => {
    setDefault();
  }, []);

  useEffect(() => {
    let totalCostNum = slider1 * getPrice(slider1)!;
    let VAT = totalCostNum * 0.1;
    if (slider1 >= 1500) {
      if (!isNaN(totalCostNum + VAT)) {
        let stringCost = comma(String(totalCostNum + VAT));
        setShortCost(totalCostNum + VAT);
        setCost(stringCost);
      }
    } else {
      setShortCost(198000);
      setCost('198,000');
    }
  }, [slider1]);

  return (
    <div id="SendingEstimate">
      <div className="sending-contents">
        <div>
          <input
            type="text"
            // onChange={e => onChangeInput(e)}
            onChange={e => onChangeInput(e)}
            min={1500}
            max={150000}
            value={comma(slider1)}
            placeholder="1,500"
            className="slider-input slider-input-text"
          />
          <span className="slider-input-text" style={{ marginLeft: '8px' }}>
            건
          </span>
        </div>
        {slider1 !== '' && slider1 < 1500 ? (
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <small className="errorMsg">
              최소 발송건 구매는 1,500건이며 구매한 발송 건수의 사용기한은
              없습니다.
            </small>
          </div>
        ) : null}

        {slider1.length >= 8 ? (
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <small className="errorMsg">
              대량 구매 견적은 문의해 주시기 바랍니다.
            </small>
          </div>
        ) : null}
        <div className="slider-sub-text">
          1건 기준 {getPrice(slider1)}원
        </div>
        <Slider
          min={1500}
          max={150000}
          step={500}
          value={slider1}
          onChange={(value: any) => {
            setSlider1(value);
          }}
          trackStyle={{ backgroundColor: '#f2ab28' }}
          handleStyle={{ borderColor: '#f2ab28' }}
        />
      </div>

      <div className="result">
        <div className="total-cost">
          <Row>
            <Col span={8}>
              <p className="title">예상 견적</p>
            </Col>
            <Col span={16} style={{ textAlign: 'right' }}>
              <span className="title">{cost}</span>
              <span className="won">원</span>
            </Col>
          </Row>

          {cost !== '-' ? (
            <div className="read-cost" style={{ width: '100%' }}>
              <span>{Math.floor(shortCost / 10000)}만</span>
              <span style={{ marginLeft: '8px' }}>
                {shortCost % 10000 ? (
                  <span style={{ marginRight: '8px' }}>
                    {shortCost % 10000}
                  </span>
                ) : null}
                원 <br/>(VAT 10% 포함)
              </span>
            </div>
          ) : (
            <div style={{ height: '36px' }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendingEstimate;
