import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { CheckboxSelection } from '../../common/checkboxSelection';
import costJson from './panelCostData.json';

const { Panel } = Collapse;

const CollapseSelection = ({
  onChange,
  onClick,
  isSelected,
  selectedArr, // 선택된 표본 심화 조건
}: {
  onChange: any;
  onClick: any;
  isSelected: boolean;
  selectedArr: string[];
}) => {
  const [openState, setOpenState] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); //
  const [job, setJob] = useState<string[]>([]);
  const [jobIncome, setJobIncome] = useState<string[]>([]);
  const [jobState, setJobState] = useState<string[]>([]);
  const [familySize, setFamilySize] = useState<string[]>([]);
  const [familyResidenceType, setFamilyResidenceType] = useState<string[]>([]);
  const [familyAnimal, setFamilyAnimal] = useState<string[]>([]);
  const [expMarriage, setExpMarriage] = useState<string[]>([]);
  const [expEconomicPeriod, setExpEconomicPeriod] = useState<string[]>([]);
  const [expDriving, setExpDriving] = useState<string[]>([]);
  const [expSmoking, setExpSmoking] = useState<string[]>([]);
  const [expDrinking, setExpDrinking] = useState<string[]>([]);
  const [totalMaxLevel, setTotalMaxLevel] = useState<number>(0);

  useEffect(() => {
    let getTotalMaxLevel = findTotalMaxLevel();
    setTotalMaxLevel(getTotalMaxLevel);
    // onChange(totalMaxLevel);
  }, [
    selectedArr,
    selectedOptions,
    job,
    jobIncome,
    jobState,
    familySize,
    familyResidenceType,
    familyAnimal,
    expMarriage,
    expEconomicPeriod,
    expDriving,
    expSmoking,
    expDrinking,
  ]);

  useEffect(() => {
    onChange(totalMaxLevel);
  }, [totalMaxLevel]);

  const findMaxLevel = (
    target: string[],
    targetInfo: { [key: string]: number }
  ) => {
    if (target.length === 0) {
      return 0;
    }
    let targetLevelArr: number[] = target.map((item: any) => {
      return targetInfo[item];
    });
    return Math.max(...targetLevelArr);
  };

  const findTotalMaxLevel = () => {
    const information = costJson.information.selectedInformation;
    const jobLevel = findMaxLevel(job, information['Job']);
    const jobIncomeLevel = findMaxLevel(jobIncome, information['Annual income']);
    const jobStateLevel = findMaxLevel(jobState, information['Employment status']);
    const familySizeLevel = findMaxLevel(familySize, information['Household size']);
    const familyResidenceTypeLevel = findMaxLevel(
      familyResidenceType,
      information['Residence type']
    );
    const familyAnimalLevel = findMaxLevel(familyAnimal, information['Pet']);
    const expMarriageLevel = findMaxLevel(
      expMarriage,
      information['Marital status']
    );
    const expEconomicPeriodLevel = findMaxLevel(
      expEconomicPeriod,
      information['Economic activity']
    );
    const expDrivingLevel = findMaxLevel(expDriving, information['Driving']);
    const expSmokingLevel = findMaxLevel(expSmoking, information['Smoking']);
    const expDrinkingLevel = findMaxLevel(
      expDrinking,
      information['Drinking frequency']
    );

    let levelArr = [
      jobLevel,
      jobIncomeLevel,
      jobStateLevel,
      familySizeLevel,
      familyResidenceTypeLevel,
      familyAnimalLevel,
      expMarriageLevel,
      expEconomicPeriodLevel,
      expDrivingLevel,
      expSmokingLevel,
      expDrinkingLevel,
    ];

    let totalLevel = Math.max(...levelArr);
    return totalLevel;
  };

  // 전체 콜랍스 아이콘
  const handleOpenState = () => {
    setOpenState(!openState);
  };

  // 표본 심화 조건 더보기 콜랍스 내에 적용 버튼
  const actionButton = document.querySelector('.action-button');
  if (isSelected) {
    actionButton?.classList.add('action-button-active');
  } else {
    actionButton?.classList.remove('action-button-active');
  }

  // 적용 버튼을 눌렀을 때, 이전 선택했던 옵션 초기화 & 새로운 옵션 적용
  const handleActionButton = () => {
    setJob([]);
    setJobIncome([]);
    setJobState([]);
    setFamilySize([]);
    setFamilyResidenceType([]);
    setFamilyAnimal([]);
    setExpMarriage([]);
    setExpEconomicPeriod([]);
    setExpDriving([]);
    setExpSmoking([]);
    setExpDrinking([]);
    setSelectedOptions(selectedArr);
    setShowOptions(true);
  };

  const jobInfo = ['Job', 'Annual income', 'Employment status'];
  const familyInfo = ['Household size', 'Residence type', 'Pet'];
  const expInfo = [
    'Marital status',
    'Economic activity',
    'Driving',
    'Smoking',
    'Drinking frequency',
  ];

  return (
    <Collapse
      ghost
      onChange={handleOpenState}
      expandIcon={() =>
        !openState ? (
          <PlusOutlined style={{ fontSize: '16px' }} />
        ) : (
          <MinusOutlined style={{ fontSize: '16px' }} />
        )
      }
    >
      <Panel
        header="View More Conditions"
        key="1"
        className="additional-conditions"
      >
        <div className="container">
          <p className="desc-msg" style={{ marginTop: '8px' }}>
            Please request more detailed conditions through{' '} 
            <a
              href="https://link.pocketsurvey.co.kr/p/8KmlhWU"
              target="_blank"
              className="small-contact-us"
              rel="noreferrer"
            >
              Inquiry
            </a>
          </p>

          {showOptions ? (
            <div>
              <CheckboxSelection
                col1={5}
                col2={19}
                title="Job"
                onChange={setJob}
                onClick={() => console.log('k')}
                options={['Student', 'Employee', 'Freelancer', 'Self-employee']}
                defaultValue={[]}
                selected={job}
                show={selectedOptions.includes('Job')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Annual income"
                onChange={setJobIncome}
                onClick={() => console.log('k')}
                options={[
                  'Below USD 26,500',
                  'USD 26,500 - 35,299',
                  'USD 35,300 - 43,999',
                  'USD 44,000 - 52,999',
                  'USD 53,000 – 61,799',
                  'USD 61,800 – 70,629',
                  'USD 70,630 – 79,499',
                  'USD 79,500 – 88,299',
                  'USD 88,300+',
                ]}
                defaultValue={[]}
                selected={jobIncome}
                show={selectedOptions.includes('Annual income')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Employment status"
                onChange={setJobState}
                onClick={() => console.log('k')}
                options={['Full-time', 'Part-time']}
                defaultValue={[]}
                selected={jobState}
                show={selectedOptions.includes('Employment status')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Household size"
                onChange={setFamilySize}
                onClick={() => console.log('k')}
                options={['1', '2', '3', '4', '5', '6+']}
                defaultValue={[]}
                selected={familySize}
                show={selectedOptions.includes('Household size')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Residence type"
                onChange={setFamilyResidenceType}
                onClick={() => console.log('k')}
                options={[
                  'Detached house',
                  'Apartment',
                  'Attached house',
                  'Officetel',
                  'Shared housing',
                ]}
                defaultValue={[]}
                selected={familyResidenceType}
                show={selectedOptions.includes('Residence type')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Pet"
                onChange={setFamilyAnimal}
                onClick={() => console.log('k')}
                options={['Dog', 'Cat']}
                defaultValue={[]}
                selected={familyAnimal}
                show={selectedOptions.includes('Pet')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Marital status"
                onChange={setExpMarriage}
                onClick={() => console.log('k')}
                options={['Single', 'Married', 'Divorced', 'Widowed']}
                defaultValue={[]}
                selected={expMarriage}
                show={selectedOptions.includes('Marital status')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Economic activity"
                onChange={setExpEconomicPeriod}
                onClick={() => console.log('k')}
                options={['Less than 2 years', '2-5 years', '6–9 years', '10 years or more']}
                defaultValue={[]}
                selected={expEconomicPeriod}
                show={selectedOptions.includes('Economic activity')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Driving"
                onChange={setExpDriving}
                onClick={() => console.log('k')}
                options={['Less than 2 years', '2–4 years', '5–9 years', '10 years or more']}
                defaultValue={[]}
                selected={expDriving}
                show={selectedOptions.includes('Driving')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Smoking"
                onChange={setExpSmoking}
                onClick={() => console.log('k')}
                options={['Yes', 'No']}
                defaultValue={[]}
                selected={expSmoking}
                show={selectedOptions.includes('Smoking')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="Drinking frequency"
                onChange={setExpDrinking}
                onClick={() => console.log('k')}
                options={[
                  'Never drink',
                  'Once a month or less often',
                  '2–3 times a month',
                  'Once a week or more often',
                ]}
                defaultValue={[]}
                selected={expDrinking}
                show={selectedOptions.includes('Drinking frequency')}
                msgShow={false}
                msg=""
              />
            </div>
          ) : null}

          <div className="contents-container">
            <div>
              <p className="info-title">Job information</p>
              <div className="info-button-container">
                {jobInfo.map(info => (
                  <input
                    type="button"
                    value={info}
                    key={info}
                    onClick={onClick}
                    className="info-button"
                  />
                ))}
              </div>
            </div>
            <div>
              <p className="info-title">Household information</p>
              <div className="info-button-container">
                {familyInfo.map(info => (
                  <input
                    type="button"
                    value={info}
                    key={info}
                    onClick={onClick}
                    className="info-button"
                  />
                ))}
              </div>
            </div>
            <div className="info-button-container">
              <p className="info-title">Experience</p>
              <div>
                {expInfo.map(info => (
                  <input
                    type="button"
                    value={info}
                    key={info}
                    onClick={onClick}
                    className="info-button"
                  />
                ))}
              </div>
            </div>
          </div>
          <input
            type="button"
            value="Apply"
            className="action-button"
            disabled={!isSelected}
            onClick={handleActionButton}
          />
        </div>
      </Panel>
    </Collapse>
  );
};

export default CollapseSelection;
