import React, { useEffect, useState } from 'react';
import '../styles.scss';
import { Row, Col, Divider, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import checkSvg from '../../assets/icons/checkBoldYellow.svg';
import { SliderSelection } from '../../common/sliderSelection';
import { RadioSelection } from './radioSelection';
import costJson from './costData.json';

const QualitativeResearchEn = () => {
  // 해당 페이지의 너비는 1140px
  const app = document.querySelector('.App');
  app?.setAttribute('style', 'min-width:1140px');

  const chatbotButton = document.querySelector('#chatbot-button');
  chatbotButton?.setAttribute('style', 'visibility: hidden');

  const scrolltopButton = document.querySelector('#chatbot-button');
  scrolltopButton?.setAttribute('style', 'visibility: hidden');

  const costVal = JSON.stringify(costJson);
  // console.log(costJson['IDI']);

  const [cost, setCost] = useState<any>(0);
  const [shortCost, setShortCost] = useState<any>(0);
  const [radio1, setRadio1] = useState<'FGI' | 'IDI' | null>('FGI'); // 심층면담 | 좌담회
  const [radio2, setRadio2] = useState<number | null>(0); // 온라인 | 오프라인
  const [slider1, setSlider1] = useState<
    '3' | '4' | '5' | '6' | '7' | '8' | null
  >(null); // 그룹 당 인원(3-8)
  const [slider2, setSlider2] = useState<number | null>(null); // 조사 횟수(1-10)
  const [slider3, setSlider3] = useState<number | null>(null); // 회당 조사 시간(1-3)
  const [optionCheckedList, setOptionCheckedList] = useState<number[]>([]);

  useEffect(() => {
    if (radio1 !== null && radio2 !== null) {
      setTotalCost();
    }
  }, [radio1, radio2, slider1, slider2, slider3, optionCheckedList]);

  useEffect(() => {
    if (radio1 !== null && radio2 !== null) {
      setDefaultVal(radio1, radio2);
    }
  }, [radio1, radio2]);

  function comma(num: string) {
    var len, point, str;

    num = num + '';
    point = num.length % 3;
    len = num.length;

    str = num.substring(0, point);
    while (point < len) {
      if (str !== '') str += ',';
      str += num.substring(point, point + 3);
      point += 3;
    }
    return str;
  }

  const getIndex = (hour: number) => {
    if (hour === 1) {
      return 0;
    } else if (hour === 1.5) {
      return 1;
    } else if (hour === 2) {
      return 2;
    } else if (hour === 2.5) {
      return 3;
    } else if (hour >= 3) {
      return 4;
    }
  };

  const getReportCostIndex = (hour: number) => {
    if (hour > 0 && hour <= 3) {
      return 0;
    } else if (hour > 3 && hour <= 5) {
      return 1;
    } else if (hour > 5 && hour <= 8) {
      return 2;
    } else if (hour > 8 && hour <= 10) {
      return 3;
    } else if (hour > 10 && hour <= 15) {
      return 4;
    } else if (hour > 15) {
      return 5;
    }
  };

  // 초기값 세팅
  const setDefaultVal = (radio1: 'IDI' | 'FGI', radio2: number) => {
    if (radio2 === 0) {
      onCheckedCheckbox([1, 2, 7]);
    } else if (radio2 === 1) {
      onCheckedCheckbox([1, 2, 7]);
    }

    if (radio1 === 'IDI') {
      setSlider1('3');
      setSlider2(5);
      setSlider3(1);
    } else if (radio1 === 'FGI') {
      setSlider1('4');
      setSlider2(3);
      setSlider3(1);
    }
  };

  const computeBasicCost = (
    radio1: 'FGI' | 'IDI', // type FGIIDIType = 'FGI' | 'IDI';
    radio2: number,
    slider1: '3' | '4' | '5' | '6' | '7' | '8',
    slider2: number,
    slider3: number
  ) => {
    // 조사 방법 + 조사 방식
    const base = costJson[radio1].basic[radio2];

    // 전문 진행
    const index = getIndex(slider3)!;
    let moderating: number = 0;
    if (radio1 === 'IDI') {
      moderating = costJson[radio1].moderating[radio2]['1'][index] * slider2;
      console.log(
        `IDI의 온/오프의 전문진행 가격 ${costJson[radio1].moderating[radio2]['1']}`
      );
    } else if (radio1 === 'FGI') {
      moderating =
        costJson[radio1].moderating[radio2][slider1][index] * slider2;
    }
    return base + moderating;
  };

  const computeAdditionalCost = (
    radio1: 'FGI' | 'IDI', // type FGIIDIType = 'FGI' | 'IDI';
    radio2: number,
    slider1: '3' | '4' | '5' | '6' | '7' | '8',
    slider2: number,
    slider3: number,
    optionCheckedList: number[]
  ) => {
    const optionsCost: number[] = [];

    const totalTime = slider2 * slider3;
    const index = getIndex(totalTime)!;
    // 인터뷰 지침 제작
    const guideline = costJson[radio1].guideline[getIndex(slider3)!];
    optionsCost.push(guideline);

    // 참여자 선별
    let recruiting =
      costJson[radio1].recruiting[radio2][getIndex(slider3)!] * slider2;
    if (radio1 === 'FGI') {
      recruiting = recruiting * parseInt(slider1);
    }
    optionsCost.push(recruiting);

    // 기록본 작성
    let scriptIndex = getIndex(slider3)!;
    let script: number =
      costJson[radio1].script[0][slider1][scriptIndex] * slider2;
    optionsCost.push(script);

    // 결과 분석 보고서
    const reportIndex = getReportCostIndex(totalTime)!;
    let report = costJson[radio1].report[reportIndex];
    if (totalTime > 20) {
      let overTime = Math.ceil(totalTime - 20);
      if (radio1 === 'IDI') {
        report += overTime * 50;
      } else if (radio1 === 'FGI') {
        report += overTime * 100;
      }
    }
    optionsCost.push(report);

    // 참여자 사례금 지급
    let pay = costJson[radio1].pay[radio2][getIndex(slider3)!] * slider2;
    if (radio1 === 'FGI') {
      pay = pay * parseInt(slider1);
    }
    optionsCost.push(pay);

    // 참여자 사전 과제
    let assignmentPay =
      radio1 === 'IDI' ? slider2 * 1 * 5 : slider2 * parseInt(slider1) * 5;
    optionsCost.push(assignmentPay);

    // 공간 및 장비 대여
    const ceilTotalTime = Math.ceil(totalTime);
    const rentCostArr = costJson[radio1].rent;

    let quotient = Math.floor(ceilTotalTime / rentCostArr.length);
    let reminder = ceilTotalTime % rentCostArr.length;
    let rent: number = rentCostArr[rentCostArr.length - 1] * quotient;
    if (reminder - 1 >= 0) {
      rent += rentCostArr[reminder - 1];
    }
    optionsCost.push(rent);

    let totalAdditionalCost = 0;
    optionCheckedList.map(index => {
      // 온라인일 때 공간 및 장비 대여는 더하지 않음.
      if (radio2 === 0 && index !== 7) {
        totalAdditionalCost += optionsCost[index - 1];
      } else if (radio2 === 1) {
        totalAdditionalCost += optionsCost[index - 1];
      }
    });
    return totalAdditionalCost;
  };

  const setTotalCost = () => {
    if (
      radio1 !== null &&
      radio2 !== null &&
      slider1 !== null &&
      slider2 !== null &&
      slider3 !== null
    ) {
      if (
        parseInt(slider1) >= 3 &&
        parseInt(slider1) <= 8 &&
        slider2 >= 1 &&
        slider2 <= 10 &&
        slider3 >= 1 &&
        slider3 <= 3
      ) {
        let basicCost = computeBasicCost(
          radio1,
          radio2,
          slider1,
          slider2,
          slider3
        );
        let additionalCost = computeAdditionalCost(
          radio1,
          radio2,
          slider1,
          slider2,
          slider3,
          optionCheckedList
        );

        let totalCostNum = basicCost + additionalCost;
        let VAT = totalCostNum * 0.1;
        if (!isNaN(totalCostNum + VAT)) {
          let stringCost = comma(String((totalCostNum + VAT) * 1000));
          setShortCost((totalCostNum + VAT) * 1000);
          setCost(stringCost);
        }
      } else {
        setCost('-');
        return;
      }
    }
  };

  const radio1Options = [
    { label: 'In-Depth Interview(IDI)', value: 'IDI' },
    { label: 'Focus Group Interview(FGI)', value: 'FGI' },
  ];

  const radio2Options = [
    { label: 'Online', value: 0 },
    { label: 'Offline', value: 1 },
  ];

  const BasicOption = ({ text }: { text: string }) => {
    return (
      <p className="selection-text" style={{ marginBottom: '36px' }}>
        <img
          src={checkSvg}
          style={{ width: '12px', marginRight: '18px' }}
          alt="checkImg"
        />
        {text}
      </p>
    );
  };

  const AdditionalOption = ({ text, value }: { text: string; value: any }) => {
    return (
      <Col span={24} style={{ marginBottom: '36px' }}>
        <Checkbox value={value} className="selection-text" checked={true}>
          <span style={{ marginLeft: '10px' }}>{text}</span>
        </Checkbox>
      </Col>
    );
  };

  const onCheckedCheckbox = (checkedValues: any) => {
    setOptionCheckedList(checkedValues);
  };

  return (
    <div id="QualitativeResearch">
      <Row>
        <Col span={15} className="contents">
          <p className="title" style={{ marginBottom: '40px' }}>
            Qualitative Research Estimate*​
          </p>

          <RadioSelection
            title="Method"
            name="radio1"
            options={radio1Options}
            onChange={(e: any) => setRadio1(e.target.value)}
            value={radio1}
            defaultValue={'FGI'}
          />

          <RadioSelection
            title="Mode"
            name="radio2"
            options={radio2Options}
            onChange={(e: any) => {
              let numVal = parseInt(e.target.value);
              setRadio2(numVal);
            }}
            value={radio2}
            defaultValue={0}
          />

          {/* 좌담회일 경우만 */}
          {radio1 === 'FGI' ? (
            <SliderSelection
              col1={5}
              col2={19}
              title="Number of participants per group"
              min={3}
              max={8}
              step={1}
              value={slider1}
              onChange={(value: any) => {
                setSlider1(value);
              }}
              defaultValue={4}
              // msg=""
            />
          ) : null}

          <SliderSelection
            col1={5}
            col2={19}
            title="Time per interview"
            min={1}
            max={3}
            step={0.5}
            value={slider3}
            onChange={(value: any) => {
              setSlider3(value);
            }}
            defaultValue={1}
            // msg=""
          />

          <SliderSelection
            col1={5}
            col2={19}
            title="Number of interviews"
            min={1}
            max={10}
            step={1}
            value={slider2}
            onChange={(value: any) => {
              setSlider2(value);
            }}
            defaultValue={slider2}
            // msg=""
          />

          <Row>
            <Col span={24} className="subtitle">
              What we offer
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <p className="selection-title" style={{ marginBottom: '30px' }}>
                Basic service
              </p>
              {radio1 && slider1 && slider2 && slider3 ? (
                <div>
                  <BasicOption text="Assigned project manger" />
                  <BasicOption text="Project management" />
                  <BasicOption text="Interview moderation" />
                  {/* 온라인/오프라인 분기 */}
                  {radio2 === 0 ? (
                    <BasicOption text="Recorded interview video" />
                  ) : (
                    <BasicOption text="Recorded interview file" />
                  )}
                  <BasicOption text="Interview scripts" />
                </div>
              ) : null}
            </Col>
            <Col span={13}>
              <p className="selection-title" style={{ marginBottom: '30px' }}>
                Additional service
              </p>
              {radio1 && slider1 && slider2 && slider3 ? (
                <Checkbox.Group
                  onChange={onCheckedCheckbox}
                  defaultValue={optionCheckedList}
                >
                  <Row>
                    <AdditionalOption
                      text="Interview guidelines
                      "
                      value={1}
                    />
                    <AdditionalOption
                      text="Participant recruitment
                      "
                      value={2}
                    />
                    {/* <AdditionalOption text="기록본(Script) 작성" value={3} /> */}
                    <AdditionalOption text="Interview analysis report" value={4} />
                    <AdditionalOption text="Participant compensation" value={5} />
                    <AdditionalOption text="Pre-assigned tasks for participants" value={6} />
                    {/* 오프라인이면 */}
                    {radio2 === 1 ? (
                      <AdditionalOption text="Rental for facilities" value={7} />
                    ) : null}
                  </Row>
                </Checkbox.Group>
              ) : null}
            </Col>
          </Row>
        </Col>

        <Col span={9}>
          <div className="result">
            <Row>
              <Col span={8}>
                <p className="title" style={{ marginBottom: '0' }}>
                  Total
                </p>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <span className="title">{cost}</span>
                <span className="won">KRW</span>
              </Col>
            </Row>
            <Divider style={{ margin: '14px 0', color: '#D2CBC0' }} />
            
            <div className="disclaimer">
              <p style={{ marginBottom: '8px' }}>
                *Above estimate includes tax.
              </p>
              <p style={{ marginBottom: '8px' }}>*The estimate is subject to change depending on the project details.</p>
              <p>*Please submit your request for a quote through Inquiry. 
Our consultant will be in contact with you in 3 to 5 business days. 
</p>
            </div>
            <div className="btn">
              <a href="http://www.pocketsurvey.co.kr/login">
                <button className="startBtn">Create a survey</button>
              </a>
              <a
                href="https://link.pocketsurvey.co.kr/p/8KmlhWU"
                target="_blank"
                rel="noreferrer"
              >
                <p className="contactUs">Inquiry</p>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default QualitativeResearchEn;
