import React from 'react';
import { Row, Col, Slider, InputNumber } from 'antd';
export const SliderSelection = ({
  col1,
  col2,
  title,
  min,
  max,
  step,
  value,
  onChange,
  defaultValue,
  msg,
}: {
  col1: number;
  col2: number;
  title: string;
  min: number;
  max: number;
  step: number;
  value: any;
  onChange: any;
  defaultValue: any;
  msg?: string;
}) => {
  return (
    <Row className="selection-container">
      <Col span={col1}>
        <p className="selection-title">{title}</p>
      </Col>
      <Col span={col2}>
        <Row>
          <Slider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            trackStyle={{ backgroundColor: '#f2ab28' }}
            handleStyle={{ borderColor: '#f2ab28' }}
          />
          {/* <input
            type="number"
            value={value}
            min={min}
            max={max}
            className="slider-input selection-text"
            style={{ margin: '0 28px', width: '70px' }}
          /> */}
          <InputNumber
            min={min}
            max={max}
            step={step}
            style={{ margin: '0 28px', width: '70px' }}
            value={value}
            onChange={onChange}
            className="selection-text slider-input"
          />
        </Row>
        {/* <div style={{ height: '30px', marginTop: '15px' }}>
          <small className="errorMsg">{msg}</small>
        </div> */}
        {value > max && msg ? (
          <div
            style={{
              marginTop: '14px',
              marginBottom: '20px',
              marginLeft: '5px',
            }}
          >
            <small className="errorMsg">
              {msg}에 대한 견적은{' '}
              <a
                href="https://link.pocketsurvey.co.kr/p/I_Qkh78"
                target="_blank"
                className="small-contact-us"
                rel="noreferrer"
              >
                문의하기
              </a>
              로 요청해 주시기 바랍니다.
            </small>
          </div>
        ) : null}
      </Col>
    </Row>
  );
};
