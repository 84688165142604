import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { CheckboxSelection } from '../../common/checkboxSelection';
import costJson from './panelCostData.json';

const { Panel } = Collapse;

const CollapseSelection = ({
  onChange,
  onClick,
  isSelected,
  selectedArr, // 선택된 표본 심화 조건
}: {
  onChange: any;
  onClick: any;
  isSelected: boolean;
  selectedArr: string[];
}) => {
  const [openState, setOpenState] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); //
  const [job, setJob] = useState<string[]>([]);
  const [jobIncome, setJobIncome] = useState<string[]>([]);
  const [jobState, setJobState] = useState<string[]>([]);
  const [familySize, setFamilySize] = useState<string[]>([]);
  const [familyResidenceType, setFamilyResidenceType] = useState<string[]>([]);
  const [familyAnimal, setFamilyAnimal] = useState<string[]>([]);
  const [expMarriage, setExpMarriage] = useState<string[]>([]);
  const [expEconomicPeriod, setExpEconomicPeriod] = useState<string[]>([]);
  const [expDriving, setExpDriving] = useState<string[]>([]);
  const [expSmoking, setExpSmoking] = useState<string[]>([]);
  const [expDrinking, setExpDrinking] = useState<string[]>([]);
  const [totalMaxLevel, setTotalMaxLevel] = useState<number>(0);

  useEffect(() => {
    let getTotalMaxLevel = findTotalMaxLevel();
    setTotalMaxLevel(getTotalMaxLevel);
    // onChange(totalMaxLevel);
  }, [
    selectedArr,
    selectedOptions,
    job,
    jobIncome,
    jobState,
    familySize,
    familyResidenceType,
    familyAnimal,
    expMarriage,
    expEconomicPeriod,
    expDriving,
    expSmoking,
    expDrinking,
  ]);

  useEffect(() => {
    onChange(totalMaxLevel);
  }, [totalMaxLevel]);

  const findMaxLevel = (
    target: string[],
    targetInfo: { [key: string]: number }
  ) => {
    if (target.length === 0) {
      return 0;
    }
    let targetLevelArr: number[] = target.map((item: any) => {
      return targetInfo[item];
    });
    return Math.max(...targetLevelArr);
  };

  const findTotalMaxLevel = () => {
    const information = costJson.information.selectedInformation;
    const jobLevel = findMaxLevel(job, information.직업);
    const jobIncomeLevel = findMaxLevel(jobIncome, information['근로 연 소득']);
    const jobStateLevel = findMaxLevel(jobState, information['고용 상태']);
    const familySizeLevel = findMaxLevel(familySize, information['구성원 수']);
    const familyResidenceTypeLevel = findMaxLevel(
      familyResidenceType,
      information['주거지 유형']
    );
    const familyAnimalLevel = findMaxLevel(familyAnimal, information.반려동물);
    const expMarriageLevel = findMaxLevel(
      expMarriage,
      information['결혼 상태']
    );
    const expEconomicPeriodLevel = findMaxLevel(
      expEconomicPeriod,
      information['경제 활동 기간']
    );
    const expDrivingLevel = findMaxLevel(expDriving, information['운전 경력']);
    const expSmokingLevel = findMaxLevel(expSmoking, information['흡연 경험']);
    const expDrinkingLevel = findMaxLevel(
      expDrinking,
      information['음주 빈도']
    );

    let levelArr = [
      jobLevel,
      jobIncomeLevel,
      jobStateLevel,
      familySizeLevel,
      familyResidenceTypeLevel,
      familyAnimalLevel,
      expMarriageLevel,
      expEconomicPeriodLevel,
      expDrivingLevel,
      expSmokingLevel,
      expDrinkingLevel,
    ];

    let totalLevel = Math.max(...levelArr);
    return totalLevel;
  };

  // 전체 콜랍스 아이콘
  const handleOpenState = () => {
    setOpenState(!openState);
  };

  // 표본 심화 조건 더보기 콜랍스 내에 적용 버튼
  const actionButton = document.querySelector('.action-button');
  if (isSelected) {
    actionButton?.classList.add('action-button-active');
  } else {
    actionButton?.classList.remove('action-button-active');
  }

  // 적용 버튼을 눌렀을 때, 이전 선택했던 옵션 초기화 & 새로운 옵션 적용
  const handleActionButton = () => {
    setJob([]);
    setJobIncome([]);
    setJobState([]);
    setFamilySize([]);
    setFamilyResidenceType([]);
    setFamilyAnimal([]);
    setExpMarriage([]);
    setExpEconomicPeriod([]);
    setExpDriving([]);
    setExpSmoking([]);
    setExpDrinking([]);
    setSelectedOptions(selectedArr);
    setShowOptions(true);
  };

  const jobInfo = ['직업', '근로 연 소득', '고용 상태'];
  const familyInfo = ['구성원 수', '주거지 유형', '반려동물'];
  const expInfo = [
    '결혼 상태',
    '경제 활동 기간',
    '운전 경력',
    '흡연 경험',
    '음주 빈도',
  ];

  return (
    <Collapse
      ghost
      onChange={handleOpenState}
      expandIcon={() =>
        !openState ? (
          <PlusOutlined style={{ fontSize: '16px' }} />
        ) : (
          <MinusOutlined style={{ fontSize: '16px' }} />
        )
      }
    >
      <Panel
        header="표본 심화 조건 더 보기"
        key="1"
        className="additional-conditions"
      >
        <div className="container">
          <p className="desc-msg" style={{ marginTop: '8px' }}>
            보다 상세한 표본 조건 설정은{' '}
            <a
              href="https://link.pocketsurvey.co.kr/p/I_Qkh78"
              target="_blank"
              className="small-contact-us"
              rel="noreferrer"
            >
              문의하기
            </a>
            를 통해 요청해주세요.
          </p>

          {showOptions ? (
            <div>
              <CheckboxSelection
                col1={5}
                col2={19}
                title="직업"
                onChange={setJob}
                onClick={() => console.log('k')}
                options={['학생', '직장인', '프리랜서', '자영업자']}
                defaultValue={[]}
                selected={job}
                show={selectedOptions.includes('직업')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="근로 연 소득"
                onChange={setJobIncome}
                onClick={() => console.log('k')}
                options={[
                  '3천만원 미만',
                  '3~4천만원 미만',
                  '4~5천만원 미만',
                  '5~6천만원 미만',
                  '6~7천만원 미만',
                  '7~8천만원 미만',
                  '8~9천만원 미만',
                  '9천만~1억원 미만',
                  '1억원 이상',
                ]}
                defaultValue={[]}
                selected={jobIncome}
                show={selectedOptions.includes('근로 연 소득')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="고용 상태"
                onChange={setJobState}
                onClick={() => console.log('k')}
                options={['풀타임', '파트타임']}
                defaultValue={[]}
                selected={jobState}
                show={selectedOptions.includes('고용 상태')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="구성원 수"
                onChange={setFamilySize}
                onClick={() => console.log('k')}
                options={['1인', '2인', '3인', '4인', '5인', '6인 이상']}
                defaultValue={[]}
                selected={familySize}
                show={selectedOptions.includes('구성원 수')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="주거지 유형"
                onChange={setFamilyResidenceType}
                onClick={() => console.log('k')}
                options={[
                  '주택',
                  '아파트',
                  '연립/빌라',
                  '오피스텔',
                  '기숙사/쉐어하우스',
                ]}
                defaultValue={[]}
                selected={familyResidenceType}
                show={selectedOptions.includes('주거지 유형')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="반려동물"
                onChange={setFamilyAnimal}
                onClick={() => console.log('k')}
                options={['강아지', '고양이']}
                defaultValue={[]}
                selected={familyAnimal}
                show={selectedOptions.includes('반려동물')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="결혼 상태"
                onChange={setExpMarriage}
                onClick={() => console.log('k')}
                options={['미혼', '기혼', '이혼', '사별']}
                defaultValue={[]}
                selected={expMarriage}
                show={selectedOptions.includes('결혼 상태')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="경제 활동 기간"
                onChange={setExpEconomicPeriod}
                onClick={() => console.log('k')}
                options={['2년 미만', '2~6년 미만', '6~10년 미만', '10년 이상']}
                defaultValue={[]}
                selected={expEconomicPeriod}
                show={selectedOptions.includes('경제 활동 기간')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="운전 경력"
                onChange={setExpDriving}
                onClick={() => console.log('k')}
                options={['2년 미만', '2~5년 미만', '5~10년 미만', '10년 이상']}
                defaultValue={[]}
                selected={expDriving}
                show={selectedOptions.includes('운전 경력')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="흡연 경험"
                onChange={setExpSmoking}
                onClick={() => console.log('k')}
                options={['유', '무']}
                defaultValue={[]}
                selected={expSmoking}
                show={selectedOptions.includes('흡연 경험')}
                msgShow={false}
                msg=""
              />

              <CheckboxSelection
                col1={5}
                col2={19}
                title="음주 빈도"
                onChange={setExpDrinking}
                onClick={() => console.log('k')}
                options={[
                  '마시지 않음',
                  '월 1회 이하',
                  '월 2~3회',
                  '주 1회 이상',
                ]}
                defaultValue={[]}
                selected={expDrinking}
                show={selectedOptions.includes('음주 빈도')}
                msgShow={false}
                msg=""
              />
            </div>
          ) : null}

          <div className="contents-container">
            <div>
              <p className="info-title">직업 정보</p>
              <div className="info-button-container">
                {jobInfo.map(info => (
                  <input
                    type="button"
                    value={info}
                    key={info}
                    onClick={onClick}
                    className="info-button"
                  />
                ))}
              </div>
            </div>
            <div className="info-button-container">
              <p className="info-title">가구 정보</p>
              <div>
                {familyInfo.map(info => (
                  <input
                    type="button"
                    value={info}
                    key={info}
                    onClick={onClick}
                    className="info-button"
                  />
                ))}
              </div>
            </div>
            <div className="info-button-container">
              <p className="info-title">경험 정보</p>
              <div>
                {expInfo.map(info => (
                  <input
                    type="button"
                    value={info}
                    key={info}
                    onClick={onClick}
                    className="info-button"
                  />
                ))}
              </div>
            </div>
          </div>
          <input
            type="button"
            value="적용"
            className="action-button"
            disabled={!isSelected}
            onClick={handleActionButton}
          />
        </div>
      </Panel>
    </Collapse>
  );
};

export default CollapseSelection;
