import React, { useEffect, useState } from 'react';
import { Row, Col, Divider, Checkbox } from 'antd';
import checkSvg from '../../assets/icons/checkBoldYellow.svg';
import { SliderSelection } from '../../common/sliderSelection';
import { CheckboxSelection } from '../../common/checkboxSelection';
import 'antd/dist/antd.css';
import '../styles.scss';
import costJson from './panelCostData.json';
import CollapseSelection from './collapseSelection';

const PanelResearch = () => {
  const [cost, setCost] = useState<any>(0);
  const [shortCost, setShortCost] = useState<any>(0);
  const [slider1, setSlider1] = useState<number | null>(null); // 문항수
  const [slider2, setSlider2] = useState<number | null>(null); // 목표 수집 응답 개수
  const [gender, setGender] = useState<string[]>([]);
  const [age, setAge] = useState<string[]>([]);
  const [region, setRegion] = useState<string[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>([]);
  const [selectedInfo, setSelectedInfo] = useState<any>([]);
  const [selectedInfoLevel, setSelectedInfoLevel] = useState<number>(0);
  const [selectedAdditionalOption, setSelectedAdditionalOption] = useState<
    number[]
  >([2]);
  const [isBasic, setIsBasic] = useState<boolean>(true);

  useEffect(() => {
    setDefaultVal();
  }, []);

  useEffect(() => {
    setTotalCost();
  }, [
    slider1,
    slider2,
    gender,
    age,
    region,
    selectedRegion,
    selectedInfo,
    selectedInfoLevel,
    selectedAdditionalOption,
  ]);

  // 초기값 세팅
  const setDefaultVal = () => {
    setSlider1(15);
    setSlider2(200);
    setGender(['여자', '남자']);
    setAge(['10대 이하', '20대', '30대', '40대', '50대', '60대 이상']);
    setRegion(['수도권', '비수도권']);
    setSelectedRegion([]);
    setSelectedInfo([]);
    setSelectedInfoLevel(0);
    setSelectedAdditionalOption([2]);
  };

  // 문항 수에 따른 발생 금액
  const getQuestionSizePrice = (value: number) => {
    if (1 <= value && value < 6) {
      return 596;
    } else if (6 <= value && value < 11) {
      return 744;
    } else if (11 <= value && value < 16) {
      return 893;
    } else if (16 <= value && value < 21) {
      return 1120;
    } else if (21 <= value && value < 30) {
      return 2400;
    } else if (30 <= value && value < 40) {
      return 2960;
    } else if (40 <= value && value < 50) {
      return 3520;
    } else if (50 <= value && value < 60) {
      return 4080;
    } else if (60 <= value && value < 70) {
      return 4640;
    } else if (70 <= value && value < 80) {
      return 5200;
    } else if (80 <= value && value < 90) {
      return 5760;
    } else if (90 <= value) {
      return 6320;
    }
  };

  // JSON
  const information = costJson.information;

  // 성별
  const computeGenderLevel = () => {
    const researchCount = slider2! * 1.3;
    const maxManCount = 554;
    if (
      gender.length === 1 &&
      gender[0] === '남자' &&
      researchCount > maxManCount
    ) {
      return 1;
    }
    if (gender.length === 0) {
      return 0;
    }

    let genderInfo: { [key: string]: number } = information.gender;
    let genderLevelArr: number[] = gender.map((item: any) => {
      return genderInfo[item];
    });
    return Math.max(...genderLevelArr);
  };

  //나이
  const computeAgeLevel = () => {
    if (age.length === 0) {
      return 0;
    }
    const set1 = new Set(age);
    const set2 = new Set([
      '10대 이하',
      '20대',
      '30대',
      '40대',
      '50대',
      '60대 이상',
    ]);
    if (set1.size === set2.size) {
      return 0;
    }

    let ageInfo: { [key: string]: number } = information.age;
    let ageLevelArr: number[] = age.map((item: any) => {
      return ageInfo[item];
    });
    return Math.max(...ageLevelArr);
  };

  // 거주지
  const computeRegionLevel = () => {
    if (region.length === 0) {
      return 0;
    }
    const set1 = new Set(region);
    const set2 = new Set(['수도권', '비수도권']);
    if (set1.size === set2.size) {
      return 0;
    }

    let regionInfo: { [key: string]: number | { [key: string]: number } } =
      information.region;
    let detailedRegionInfo: { [key: string]: number } =
      information.region['지역 더 보기'];
    let regionLevelArr: any = [];
    let regionLevel: any = region.map((item: any) => {
      if (item === '지역 더 보기') {
        if (selectedRegion.length === 0) {
          return [0];
        }
        let selectedRegionLevelArr: any = selectedRegion.map((item: any) => {
          return detailedRegionInfo[item];
        });
        regionLevelArr = selectedRegionLevelArr;
      } else {
        regionLevelArr.push(regionInfo[item]);
      }
      return regionLevelArr;
    });

    if (regionLevel[0]) {
      if (Math.max(...regionLevel[0]) >= 0) {
        return Math.max(...regionLevel[0]);
      }
    }
  };

  // 한 응답 당 금액 계산
  const computeEachPersonCost = () => {
    const levelArr: any = [];
    const genderLevel = computeGenderLevel();
    levelArr.push(genderLevel);

    const ageLevel = computeAgeLevel();
    levelArr.push(ageLevel);

    const regionLevel = computeRegionLevel();
    levelArr.push(regionLevel);

    let totalLevel = Math.max(...levelArr, selectedInfoLevel);
    let isBasicLevel = totalLevel === 0 ? true : false;
    let LevelCost = costJson.prices[totalLevel];
    let questionSizeCost: any = slider1 ? getQuestionSizePrice(slider1) : 0;
    let totalPersonCost = LevelCost + questionSizeCost;
    return [totalPersonCost, isBasicLevel];
  };

  // 부가 서비스 금액 계산
  const computeAdditionalCost = (arr: number[]) => {
    const additionalCost = costJson.additional_price;
    let result = 0;
    for (let i = 0; i < arr.length; i++) {
      result += additionalCost[arr[i] - 1];
    }
    return result;
  };

  // 전체 금액 합산
  const setTotalCost = () => {
    if (slider1 !== null && slider2 !== null) {
      if (slider1 < 100 && slider2 < 2001) {
        let basicCost = costJson.basic;
        let deliveryCost = computeEachPersonCost()[1] ? 0 : costJson.delivery;
        let totalPersonCost = computeEachPersonCost()[0] * (slider2 * 1.3);
        let additionalOptionCost = computeAdditionalCost(
          selectedAdditionalOption
        );
        let totalCostNum =
          basicCost + totalPersonCost + deliveryCost + additionalOptionCost;
        let VAT = totalCostNum * 0.1;
        let sumCostVAT = Math.round(totalCostNum + VAT);

        if (!isNaN(sumCostVAT)) {
          let stringCost = comma(String(sumCostVAT));
          setShortCost(sumCostVAT);
          setCost(stringCost);
        }
      } else {
        setCost('-');
      }
    }
  };

  function comma(num: string) {
    var len, point, str;

    num = num + '';
    point = num.length % 3;
    len = num.length;

    str = num.substring(0, point);
    while (point < len) {
      if (str !== '') str += ',';
      str += num.substring(point, point + 3);
      point += 3;
    }
    return str;
  }

  const BasicOption = ({ text }: { text: string }) => {
    return (
      <p className="selection-text" style={{ marginBottom: '20px' }}>
        <img
          src={checkSvg}
          style={{ width: '12px', marginRight: '18px' }}
          alt="checkImg"
        />
        {text}
      </p>
    );
  };

  const AdditionalOption = ({ text, value }: { text: string; value: any }) => {
    return (
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Checkbox value={value} className="selection-text" checked={true}>
          <span style={{ marginLeft: '22px' }}>{text}</span>
        </Checkbox>
      </Col>
    );
  };

  // 지역-지역 더보기 내의 지역 선택
  const handleSelectedRegion = (e: any) => {
    let newSelectedRegion: string[] = [...selectedRegion];
    let target = e.target;
    if (target.classList.contains('region-checked')) {
      target.classList.remove('region-checked');
      newSelectedRegion = newSelectedRegion.filter(
        region => region !== target.value
      );
    } else {
      target.classList.add('region-checked');
      newSelectedRegion.push(target.value);
    }
    setSelectedRegion(newSelectedRegion);
  };

  // 표본 심화 조건 더보기 내의 원하는 정보 선택
  const handleSelectedInfo = (e: any) => {
    let newSelectedInfo: string[] = [...selectedInfo];
    let target = e.target;
    if (target.classList.contains('info-checked')) {
      target.classList.remove('info-checked');
      newSelectedInfo = newSelectedInfo.filter(info => info !== target.value);
    } else {
      target.classList.add('info-checked');
      newSelectedInfo.push(target.value);
    }
    setSelectedInfo(newSelectedInfo);
  };

  // 표본 심화 조건 더보기 내의 원하는 정보 선택 + 각 정보 입력값 변화
  const handleSelectedInfoLevel = (value: number) => {
    setSelectedInfoLevel(value);
  };

  // 부가 서비스 기능 추가
  const onCheckedCheckbox = (checkedValues: any) => {
    setSelectedAdditionalOption(checkedValues);
  };

  // 수도권, 비수도권 || 지역 더 보기 라디오 설정
  function onChangeSelectedRegion(checkedRegionArr: any) {
    let oldRegion = [...region];
    if (oldRegion.length === 1 && oldRegion.includes('지역 더 보기')) {
      if (checkedRegionArr.length >= 2) {
        let targetArr = checkedRegionArr.slice(1);
        setRegion(targetArr);
        setSelectedRegion([]);
        return;
      }
    }
    if (oldRegion.includes('수도권') || oldRegion.includes('비수도권')) {
      if (checkedRegionArr.includes('지역 더 보기')) {
        setRegion(['지역 더 보기']);
        return;
      }
    }
    setRegion(checkedRegionArr);
    return;
  }

  return (
    <div id="PanelResearch">
      <Row>
        <Col span={15} className="contents">
          <p className="title">패널 조사 견적 미리보기*​</p>

          <Row>
            <Col span={24} className="subtitle">
              1. 조사 정보
            </Col>
          </Row>
          <SliderSelection
            col1={5}
            col2={19}
            title="문항수"
            min={1}
            max={99}
            step={1}
            value={slider1}
            onChange={(value: any) => {
              setSlider1(value);
            }}
            defaultValue={1}
            msg="99개를 초과하는 문항수"
          />
          <SliderSelection
            col1={5}
            col2={19}
            title={`목표 수집 응답수`}
            min={1}
            max={2000}
            step={1}
            value={slider2}
            onChange={(value: any) => {
              setSlider2(value);
            }}
            defaultValue={1}
            msg="2000개를 초과하는 목표 수집 응답수"
          />

          <Row>
            <Col span={24} className="subtitle">
              2. 표본 조건
            </Col>
          </Row>
          <CheckboxSelection
            col1={5}
            col2={19}
            title="성별"
            onChange={setGender}
            onClick={null}
            options={['여자', '남자']}
            defaultValue={['여자', '남자']}
            selected={gender}
            show={true}
            msgShow={false}
            msg=""
          />
          <CheckboxSelection
            col1={5}
            col2={19}
            title="연령대"
            onChange={setAge}
            onClick={null}
            options={['10대 이하', '20대', '30대', '40대', '50대', '60대 이상']}
            defaultValue={[
              '10대 이하',
              '20대',
              '30대',
              '40대',
              '50대',
              '60대 이상',
            ]}
            selected={age}
            show={true}
            msgShow={
              age.length !== 6 &&
              (age.includes('10대 이하') || age.includes('60대 이상'))
            }
            msg="보다 세부적인 연령 조건 설정"
          />
          <CheckboxSelection
            col1={5}
            col2={19}
            title="지역"
            onChange={onChangeSelectedRegion}
            onClick={handleSelectedRegion}
            options={['수도권', '비수도권', '지역 더 보기']}
            defaultValue={['수도권', '비수도권']}
            selected={region}
            show={true}
            msgShow={region.includes('지역 더 보기')}
            msg="보다 세부적인 지역 조건 설정 등"
          />

          <Divider
            style={{
              marginTop: '30px',
              marginBottom: '8px',
              color: '#DFDEDD',
            }}
          />

          <CollapseSelection
            onChange={handleSelectedInfoLevel}
            onClick={handleSelectedInfo}
            isSelected={selectedInfo.length !== 0 ? true : false}
            selectedArr={selectedInfo}
          ></CollapseSelection>

          <Divider
            style={{
              marginTop: '8px',
              marginBottom: '0',
              color: '#DFDEDD',
            }}
          />

          <Row>
            <Col span={24} className="subtitle">
              3. 프로젝트 포함 내용
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <p
                className="selection-title"
                style={{ marginTop: '30px', marginBottom: '20px' }}
              >
                기본
              </p>

              <div>
                <BasicOption text="담당 컨설턴트 배정" />
                <BasicOption text="조사 프로젝트 운영" />
                <BasicOption text="설문 제작 도움(1회)" />
                <BasicOption text="파일럿 테스트 제공" />
                <BasicOption text="패널 응답 관리" />
                {/* <BasicOption text="설문 세팅" /> */}
                <BasicOption text="설문 raw data 제공" />
              </div>
            </Col>
            <Col span={12}>
              <p
                className="selection-title"
                style={{ marginTop: '30px', marginBottom: '20px' }}
              >
                부가 서비스
              </p>

              <Checkbox.Group
                onChange={onCheckedCheckbox}
                defaultValue={selectedAdditionalOption}
              >
                <Row>
                  <AdditionalOption text="설문 설계 컨설팅" value={1} />
                  <AdditionalOption text="인공지능 보고서" value={2} />
                  <AdditionalOption text="맞춤형 보고서" value={3} />
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        </Col>

        <Col span={9}>
          <div className="result">
            <div className="result-content">
              <Row>
                <Col span={10}>
                  <p className="title" style={{ marginBottom: '0' }}>
                    예상 견적
                  </p>
                </Col>
                <Col span={14} style={{ textAlign: 'right' }}>
                  <span className="title">{cost}</span>
                  <span className="won">원</span>
                </Col>
              </Row>
              <Divider style={{ margin: '14px 0', color: '#D2CBC0' }} />
              {cost !== '-' ? (
                <div className="read-cost">
                  <span>{Math.floor(shortCost / 10000)}만</span>
                  <span style={{ marginLeft: '8px' }}>
                    {shortCost % 10000 ? (
                      <span style={{ marginRight: '8px' }}>
                        {shortCost % 10000}
                      </span>
                    ) : null}
                    원 <br /> (VAT 10% 포함)
                  </span>
                </div>
              ) : (
                <div style={{ height: '36px' }}></div>
              )}
              
              <a href="https://www.pocketsurvey.co.kr/survey1/I_Qkh78">
                <button className="startBtn">패널조사 견적 문의</button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PanelResearch;