import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import QualitativeResearch from './QualitativeResearch/ko'
import QualitativeResearchEn from './QualitativeResearch/en'
import SendingEstimate from './SendingEstimate'
import PanelResearch from './PanelResearch/ko'
import PanelResearchEn from './PanelResearch/en'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            path="/qualitative-research-price"
            component={QualitativeResearch}
          ></Route>
          <Route
            path="/qualitative-research-price-en"
            component={QualitativeResearchEn}
          ></Route>
          <Route path="/sending-price" component={SendingEstimate}></Route>
          <Route path="/panel-research-price" component={PanelResearch}></Route>
          <Route
            path="/panel-research-price-en"
            component={PanelResearchEn}
          ></Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
