import React, { useEffect, useState } from 'react'
import '../styles.scss'
import { Row, Col, Divider, Checkbox } from 'antd'
import 'antd/dist/antd.css'
import checkSvg from '../../assets/icons/checkBoldYellow.svg'
import { SliderSelection } from '../../common/sliderSelection'
import { RadioSelection } from './radioSelection'
import costJson from './costData.json'

const QualitativeResearch = () => {
  // 해당 페이지의 너비는 1140px
  const app = document.querySelector('.App')
  app?.setAttribute('style', 'min-width:1140px')

  const chatbotButton = document.querySelector('#chatbot-button')
  chatbotButton?.setAttribute('style', 'visibility: hidden')

  const scrolltopButton = document.querySelector('#chatbot-button')
  scrolltopButton?.setAttribute('style', 'visibility: hidden')

  const costVal = JSON.stringify(costJson)
  // console.log(costJson['IDI']);

  const [cost, setCost] = useState<any>(0)
  const [shortCost, setShortCost] = useState<any>(0)
  const [radio1, setRadio1] = useState<'FGI' | 'IDI' | null>('FGI') // 심층면담 | 좌담회
  const [radio2, setRadio2] = useState<number | null>(0) // 온라인 | 오프라인
  const [slider1, setSlider1] = useState<
    '3' | '4' | '5' | '6' | '7' | '8' | null
  >(null) // 그룹 당 인원(3-8)
  const [slider2, setSlider2] = useState<number | null>(null) // 조사 횟수(1-10)
  const [slider3, setSlider3] = useState<number | null>(null) // 회당 조사 시간(1-3)
  const [optionCheckedList, setOptionCheckedList] = useState<number[]>([])

  useEffect(() => {
    if (radio1 !== null && radio2 !== null) {
      setTotalCost()
    }
  }, [radio1, radio2, slider1, slider2, slider3, optionCheckedList])

  useEffect(() => {
    if (radio1 !== null && radio2 !== null) {
      setDefaultVal(radio1, radio2)
    }
  }, [radio1, radio2])

  function comma(num: string) {
    var len, point, str

    num = num + ''
    point = num.length % 3
    len = num.length

    str = num.substring(0, point)
    while (point < len) {
      if (str !== '') str += ','
      str += num.substring(point, point + 3)
      point += 3
    }
    return str
  }

  const getIndex = (hour: number) => {
    if (hour === 1) {
      return 0
    } else if (hour === 1.5) {
      return 1
    } else if (hour === 2) {
      return 2
    } else if (hour === 2.5) {
      return 3
    } else if (hour >= 3) {
      return 4
    }
  }

  const getReportCostIndex = (hour: number) => {
    if (hour > 0 && hour <= 3) {
      return 0
    } else if (hour > 3 && hour <= 5) {
      return 1
    } else if (hour > 5 && hour <= 8) {
      return 2
    } else if (hour > 8 && hour <= 10) {
      return 3
    } else if (hour > 10 && hour <= 15) {
      return 4
    } else if (hour > 15) {
      return 5
    }
  }

  // 초기값 세팅
  const setDefaultVal = (radio1: 'IDI' | 'FGI', radio2: number) => {
    if (radio2 === 0) {
      onCheckedCheckbox([1, 2, 7])
    } else if (radio2 === 1) {
      onCheckedCheckbox([1, 2, 7])
    }

    if (radio1 === 'IDI') {
      setSlider1('3')
      setSlider2(5)
      setSlider3(1)
    } else if (radio1 === 'FGI') {
      setSlider1('4')
      setSlider2(3)
      setSlider3(1)
    }
  }

  const computeBasicCost = (
    radio1: 'FGI' | 'IDI', // type FGIIDIType = 'FGI' | 'IDI';
    radio2: number,
    slider1: '3' | '4' | '5' | '6' | '7' | '8',
    slider2: number,
    slider3: number
  ) => {
    // 조사 방법 + 조사 방식
    const base = costJson[radio1].basic[radio2]

    // 전문 진행
    const index = getIndex(slider3)!
    let moderating: number = 0
    if (radio1 === 'IDI') {
      moderating = costJson[radio1].moderating[radio2]['1'][index] * slider2
      console.log(
        `IDI의 온/오프의 전문진행 가격 ${costJson[radio1].moderating[radio2]['1']}`
      )
    } else if (radio1 === 'FGI') {
      moderating = costJson[radio1].moderating[radio2][slider1][index] * slider2
    }
    return base + moderating
  }

  const computeAdditionalCost = (
    radio1: 'FGI' | 'IDI', // type FGIIDIType = 'FGI' | 'IDI';
    radio2: number,
    slider1: '3' | '4' | '5' | '6' | '7' | '8',
    slider2: number,
    slider3: number,
    optionCheckedList: number[]
  ) => {
    const optionsCost: number[] = []

    const totalTime = slider2 * slider3
    const index = getIndex(totalTime)!
    // 인터뷰 지침 제작
    const guideline = costJson[radio1].guideline[getIndex(slider3)!]
    optionsCost.push(guideline)

    // 참여자 선별
    let recruiting =
      costJson[radio1].recruiting[radio2][getIndex(slider3)!] * slider2
    if (radio1 === 'FGI') {
      recruiting = recruiting * parseInt(slider1)
    }
    optionsCost.push(recruiting)

    // 기록본 작성
    let scriptIndex = getIndex(slider3)!
    let script: number =
      costJson[radio1].script[0][slider1][scriptIndex] * slider2
    optionsCost.push(script)

    // 결과 분석 보고서
    const reportIndex = getReportCostIndex(totalTime)!
    let report = costJson[radio1].report[reportIndex]
    if (totalTime > 20) {
      let overTime = Math.ceil(totalTime - 20)
      if (radio1 === 'IDI') {
        report += overTime * 50
      } else if (radio1 === 'FGI') {
        report += overTime * 100
      }
    }
    optionsCost.push(report)

    // 참여자 사례금 지급
    let pay = costJson[radio1].pay[radio2][getIndex(slider3)!] * slider2
    if (radio1 === 'FGI') {
      pay = pay * parseInt(slider1)
    }
    optionsCost.push(pay)

    // 참여자 사전 과제
    let assignmentPay =
      radio1 === 'IDI' ? slider2 * 1 * 5 : slider2 * parseInt(slider1) * 5
    optionsCost.push(assignmentPay)

    // 공간 및 장비 대여
    const ceilTotalTime = Math.ceil(totalTime)
    const rentCostArr = costJson[radio1].rent

    let quotient = Math.floor(ceilTotalTime / rentCostArr.length)
    let reminder = ceilTotalTime % rentCostArr.length
    let rent: number = rentCostArr[rentCostArr.length - 1] * quotient
    if (reminder - 1 >= 0) {
      rent += rentCostArr[reminder - 1]
    }
    optionsCost.push(rent)

    let totalAdditionalCost = 0
    optionCheckedList.map(index => {
      // 온라인일 때 공간 및 장비 대여는 더하지 않음.
      if (radio2 === 0 && index !== 7) {
        totalAdditionalCost += optionsCost[index - 1]
      } else if (radio2 === 1) {
        totalAdditionalCost += optionsCost[index - 1]
      }
    })
    return totalAdditionalCost
  }

  const setTotalCost = () => {
    if (
      radio1 !== null &&
      radio2 !== null &&
      slider1 !== null &&
      slider2 !== null &&
      slider3 !== null
    ) {
      if (
        parseInt(slider1) >= 3 &&
        parseInt(slider1) <= 8 &&
        slider2 >= 1 &&
        slider2 <= 10 &&
        slider3 >= 1 &&
        slider3 <= 3
      ) {
        let basicCost = computeBasicCost(
          radio1,
          radio2,
          slider1,
          slider2,
          slider3
        )
        let additionalCost = computeAdditionalCost(
          radio1,
          radio2,
          slider1,
          slider2,
          slider3,
          optionCheckedList
        )

        let totalCostNum = basicCost + additionalCost
        let VAT = totalCostNum * 0.1
        if (!isNaN(totalCostNum + VAT)) {
          let stringCost = comma(String((totalCostNum + VAT) * 1000))
          setShortCost((totalCostNum + VAT) * 1000)
          setCost(stringCost)
        }
      } else {
        setCost('-')
        return
      }
    }
  }

  const radio1Options = [
    { label: '1:1 인터뷰(IDI)', value: 'IDI' },
    { label: '그룹 인터뷰(FGI)', value: 'FGI' },
  ]

  const radio2Options = [
    { label: '온라인', value: 0 },
    { label: '오프라인', value: 1 },
  ]

  const BasicOption = ({ text }: { text: string }) => {
    return (
      <p className="selection-text" style={{ marginBottom: '36px' }}>
        <img
          src={checkSvg}
          style={{ width: '12px', marginRight: '18px' }}
          alt="checkImg"
        />
        {text}
      </p>
    )
  }

  const AdditionalOption = ({ text, value }: { text: string; value: any }) => {
    return (
      <Col span={24} style={{ marginBottom: '36px' }}>
        <Checkbox value={value} className="selection-text" checked={true}>
          <span style={{ marginLeft: '22px' }}>{text}</span>
        </Checkbox>
      </Col>
    )
  }

  const onCheckedCheckbox = (checkedValues: any) => {
    setOptionCheckedList(checkedValues)
  }

  return (
    <div id="QualitativeResearch">
      <Row>
        <Col span={15} className="contents">
          <p className="title" style={{ marginBottom: '40px' }}>
            정성 조사 견적 미리보기*​
          </p>

          <RadioSelection
            title="조사 방법"
            name="radio1"
            options={radio1Options}
            onChange={(e: any) => setRadio1(e.target.value)}
            value={radio1}
            defaultValue={'FGI'}
          />

          <RadioSelection
            title="조사 방식"
            name="radio2"
            options={radio2Options}
            onChange={(e: any) => {
              let numVal = parseInt(e.target.value)
              setRadio2(numVal)
            }}
            value={radio2}
            defaultValue={0}
          />

          {/* 좌담회일 경우만 */}
          {radio1 === 'FGI' ? (
            <SliderSelection
              col1={5}
              col2={19}
              title="그룹 당 인원"
              min={3}
              max={8}
              step={1}
              value={slider1}
              onChange={(value: any) => {
                setSlider1(value)
              }}
              defaultValue={4}
              msg="8명을 초과하는 그룹 당 인원"
            />
          ) : null}

          <SliderSelection
            col1={5}
            col2={19}
            title="회당 조사 시간"
            min={1}
            max={3}
            step={0.5}
            value={slider3}
            onChange={(value: any) => {
              setSlider3(value)
            }}
            defaultValue={1}
            msg="3시간을 초과하는 회당 조사 시간"
          />

          <SliderSelection
            col1={5}
            col2={19}
            title="조사 횟수"
            min={1}
            max={10}
            step={1}
            value={slider2}
            onChange={(value: any) => {
              setSlider2(value)
            }}
            defaultValue={slider2}
            msg="10번을 초과하는 조사 횟수"
          />

          <Row>
            <Col span={24} className="subtitle">
              프로젝트 포함 내용
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <p className="selection-title" style={{ marginBottom: '30px' }}>
                기본
              </p>
              {radio1 && slider1 && slider2 && slider3 ? (
                <div>
                  <BasicOption text="전담 컨설턴트 배정" />
                  <BasicOption text="조사 프로젝트 운영" />
                  <BasicOption text="인터뷰 진행(Moderating)" />
                  {/* 온라인/오프라인 분기 */}
                  {radio2 === 0 ? (
                    <BasicOption text="인터뷰 녹화본" />
                  ) : (
                    <BasicOption text="인터뷰 녹음본" />
                  )}
                  <BasicOption text="인터뷰 기록본(Script)" />
                </div>
              ) : null}
            </Col>
            <Col span={12}>
              <p className="selection-title" style={{ marginBottom: '30px' }}>
                부가 서비스
              </p>
              {radio1 && slider1 && slider2 && slider3 ? (
                <Checkbox.Group
                  onChange={onCheckedCheckbox}
                  defaultValue={optionCheckedList}
                >
                  <Row>
                    <AdditionalOption
                      text="인터뷰 지침(Guideline) 제작"
                      value={1}
                    />
                    <AdditionalOption
                      text="참여자 선별(Recruiting)"
                      value={2}
                    />
                    {/* <AdditionalOption text="기록본(Script) 작성" value={3} /> */}
                    <AdditionalOption text="결과 분석 보고서" value={4} />
                    <AdditionalOption text="참여자 사례금 지급" value={5} />
                    <AdditionalOption text="참여자 사전 과제 부여" value={6} />
                    {/* 오프라인이면 */}
                    {radio2 === 1 ? (
                      <AdditionalOption text="공간 및 장비 대여" value={7} />
                    ) : null}
                  </Row>
                </Checkbox.Group>
              ) : null}
            </Col>
          </Row>
        </Col>

        <Col span={9}>
          <div className="result">
            <Row>
              <Col span={10}>
                <p className="title" style={{ marginBottom: '0' }}>
                  예상 견적
                </p>
              </Col>
              <Col span={14} style={{ textAlign: 'right' }}>
                <span className="title">{cost}</span>
                <span className="won">원</span>
              </Col>
            </Row>
            <Divider style={{ margin: '14px 0', color: '#D2CBC0' }} />
            {cost !== '-' ? (
              <div className="read-cost">
                <span>{Math.floor(shortCost / 10000)}만</span>
                <span style={{ marginLeft: '8px' }}>
                  {shortCost % 10000 ? (
                    <span style={{ marginRight: '8px' }}>
                      {shortCost % 10000}
                    </span>
                  ) : null}
                  원 <br /> (VAT 10% 포함)
                </span>
              </div>
            ) : (
              <div style={{ height: '36px' }}></div>
            )}
            <a href="https://www.pocketsurvey.co.kr/survey1/I_Qkh78">
              <button className="startBtn">정성조사 견적 문의</button>
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default QualitativeResearch
